<template>
  <div class="row">
    <div class="col-12 g"></div>
    <div class="col-12 col-lg-8 c">
      <div class="card">
        <div class="card-header">
          <h4>
            <i class="fa fa-plus"></i>
            إضافة واستيراد الارقام
          </h4>
        </div>
        <div class="card-body">
          <!-- basic modal -->
          <b-modal
            id="modal-create-group"
            title="إنشاء مجموعة جديدة"
            hide-footer
          >
            <b-overlay :show="loading">
              <div class="form-group">
                <label for="">العنوان (اسم المجموعة)</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="createGroup.title"
                  placeholder="اكتب هنا..."
                />
              </div>
              <div class="col-12 text-center">
                <button class="btn btn-primary" @click="createGroupNow()">
                  انشاء المجموعة
                </button>
              </div>
            </b-overlay>
          </b-modal>
          <div class="form-group col-12 col-lg-7" v-if="type != 10">
            <label for=""
              >إضافة الارقام التالية إلى المجموعة:
              <button class="btn btn-sm btn-link" v-b-modal.modal-create-group>
                <span><i class="fa fa-plus"></i> إنشاء مجموعة جديدة</span>
              </button></label
            >
            <select class="form-control" v-model="group_id">
              <option :value="null">بدون مجموعة</option>
              <template v-for="group in groups">
                <option :value="group._id" :key="group._id">
                  {{ group.title }}
                </option>
              </template>
              <option value="create-groups" v-if="type == 4">
                اضافة الطلاب في مجموعات باسماء الصفوف
              </option>
              <option value="create-groups" v-if="type == 16">
                انشاء مجموعة لكل اسم مجموعة في الملف
              </option>
            </select>
          </div>
          <div
            class="col-12 text-center g"
            v-if="type != 0 && contacts.length > 0"
          >
            <button class="btn btn-relief-success" @click="addContacts()" v-if="!lll">
              إضافة الأرقام الآن
            </button>
            <button class="btn btn-success disabled" v-if="lll">
              جاري التحميل...
            </button>
          </div>
          <div class="row text-center" v-if="type == 0">
            <div class="col-12 col-lg-4 g">
              <div
                class="card card-body border shadow select-way-to-add"
                @click="type = 1"
              >
                <div class="col-12 text-center">
                  <img
                    :src="require('@/assets/images/add-way-1.png')"
                    style="width: 60%"
                    class="c"
                    alt=""
                  />
                  <br />
                  <br />
                  كتابة الأرقام يدوياً
                  <br />
                  (تكتب كل رقم فردياً)
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 g">
              <div
                class="card card-body border shadow select-way-to-add"
                @click="type = 2"
              >
                <div class="col-12 text-center">
                  <img
                    :src="require('@/assets/images/add-way-2.png')"
                    style="width: 60%"
                    class="c"
                    alt=""
                  />
                  <br />
                  <br />
                  لصق الأرقام <br />
                  (كل رقم في سطر)
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 g">
              <div
                class="card card-body border shadow select-way-to-add"
                @click="
                  type = 3;
                  listenCSV();
                "
              >
                <div class="col-12 text-center">
                  <img
                    :src="require('@/assets/images/add-way-3.png')"
                    style="width: 60%"
                    class="c"
                    alt=""
                  />
                  <br />
                  <br />
                  استيراد الأرقام <br />
                  (من ملف CSV)
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 g">
              <div
                class="card card-body border shadow select-way-to-add"
                @click="
                  type = 5;
                  listenGoogleContacts();
                "
              >
                <div class="col-12 text-center">
                  <img
                    :src="require('@/assets/images/google-contacts.png')"
                    style="width: 60%; border-radius: 10px"
                    class="c"
                    alt=""
                  />
                  <br />
                  <br />
                  استيراد من جهات اتصال جوجل <br />
                  (من ملف CSV)
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 g">
              <div
                class="card card-body border shadow select-way-to-add"
                @click="
                  type = 16;
                  listenXLS();
                "
              >
                <div class="col-12 text-center">
                  <img
                    :src="require('@/assets/images/excel.png')"
                    style="width: 60%; border-radius: 10px"
                    class="c"
                    alt=""
                  />
                  <br />
                  <br />
                  من ملف اكسل
                  <br>
                  Excel
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 g">
              <div
                class="card card-body border shadow select-way-to-add"
                @click="
                  type = 4;
                  listenNoor();
                "
              >
                <div class="col-12 text-center">
                  <img
                    :src="require('@/assets/images/noor.jpg')"
                    style="width: 60%; border-radius: 10px"
                    class="c"
                    alt=""
                  />
                  <br />
                  <br />
                  من ملف الارشاد الاكاديمي
                  <br />
                  (الاستيراد من نظام نور)
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 g">
              <div
                class="card card-body border shadow select-way-to-add"
                @click="
                  type = 10
                "
              >
                <div class="col-12 text-center">
                  <img
                    :src="require('@/assets/images/tahdir.png')"
                    style="width: 60%; border-radius: 10px"
                    class="c"
                    alt=""
                  />
                  <br />
                  <br />
                  من التحضير الذكي
                  <br />
                  (استيراد البيانات من حسابك)
                </div>
              </div>
            </div>
          </div>
          <!-- add manual -->
          <div class="col-12" v-if="type == 1">
            <div class="card card-body border">
              <span>كم رقم تريد اضافته؟</span>
              <div class="input-group mb-3 col-12 col-lg-6">
                <input
                  type="number"
                  v-model="numbers_to_add"
                  class="form-control"
                />
                <div class="input-group-append">
                  <span
                    @click="addNumbers()"
                    class="input-group-text bg-success text-white"
                    style="cursor: pointer"
                    >إضافة الحقول</span
                  >
                </div>
              </div>

              <div
                class="row"
                v-for="(contact, index) in contacts"
                :key="index"
              >
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="">الإسم (اختياري)</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="contact.name"
                    />
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="">الهاتف</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="contact.phone"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- add from paste -->
          <div class="col-12" v-if="type == 2">
            <div class="card card-body border">
              <div class="form-group">
                <label for="">الصق الأرقام هنا:</label>
                <textarea
                  class="form-control"
                  v-model="textareavalue"
                  @keyup="textareaNumbersHandle()"
                  rows="10"
                  placeholder="مثال: 
9665555555
9665555555
9665555555
---- او ---
الرقم وبعده الاسم
مثل
9665555555,محمد
9665555555,احمد
9665555555,علي"
                ></textarea>
              </div>

              <div
                class="row"
                v-for="(contact, index) in contacts"
                :key="index"
              >
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="">الإسم (اختياري)</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="contact.name"
                    />
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="">الهاتف</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="contact.phone"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- import csv -->
          <div class="col-12" v-if="type == 3">
            <div class="card card-body border">
              <div class="col-12">
                الرقم وبعده الإسم، او الرقم فقط
                <a href="/example.csv" download="example.csv"
                  ><i class="fa fa-file"></i> تحميل مثال للملف</a
                >
                <br /><br />
                <input id="upload" type="file" v-if="contacts.length == 0" />
              </div>
              <div
                class="row"
                v-for="(contact, index) in contacts"
                :key="index"
              >
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="">الإسم (اختياري)</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="contact.name"
                    />
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="">الهاتف</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="contact.phone"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- import csv -->
          <div class="col-12" v-if="type == 5">
            <div class="card card-body border">
              <div class="col-12">
                قم برفع ملف Google CSV الذي تم تصديره من جهات اتصال جوجل.
                <br /><br />
                <input id="upload" type="file" v-if="contacts.length == 0" />
              </div>
              <div
                class="row"
                v-for="(contact, index) in contacts"
                :key="index"
              >
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="">الإسم (اختياري)</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="contact.name"
                    />
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="">الهاتف</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="contact.phone"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- import noor -->
          <div class="col-12" v-if="type == 4">
            <div class="card card-body border">
              <div class="col-12">
                ملف الإرشاد الطلابي/كشف الطلاب من نظام نور
                <br /><br />
                <input
                  id="ingredient_file"
                  type="file"
                  v-if="contacts.length == 0"
                />
                <div id="progress-wrp">
                  <div class="progress-bar"></div>
                  <div class="status">0%</div>
                </div>
              </div>
              <div
                class="row"
                v-for="(contact, index) in contacts"
                :key="index"
              >
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="">الإسم (اختياري)</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="contact.name"
                    />
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="">الهاتف</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="contact.phone"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- import excel -->
          <div class="col-12" v-if="type == 16">
            <div class="card card-body">
              <div class="col-12">
                ملف الاكسل
                <br /><br />
                <input
                  id="ingredient_file"
                  type="file"
                  v-if="contacts.length == 0"
                />
                <div id="progress-wrp">
                  <div class="progress-bar"></div>
                  <div class="status">0%</div>
                </div>
              </div>
              <div class="col-12 g" v-if="inputs.length">
                <br>
                <div class="form-group">
                    <h5 for="">اختر حقل الاسم</h5>
                    <select class="form-control" v-model="name_input">
                      <option value="">-- اختر --</option>
                      <option v-for="i in inputs" :key="i.index" :value="i.index">{{ i.title }}</option>
                    </select>
                  </div>
                <div class="form-group">
                    <h5 for="">اختر حقل رقم الهاتف</h5>
                    <select class="form-control" v-model="phone_input">
                      <option value="">-- اختر --</option>
                      <option v-for="i in inputs" :key="i.index" :value="i.index">{{ i.title }}</option>
                    </select>
                  </div>
                <div class="form-group" v-if="group_id == 'create-groups'">
                    <h5 for="">اختر حقل اسم المجموعة</h5>
                    <select class="form-control" v-model="group_input">
                      <option value="">-- اختر --</option>
                      <option v-for="i in inputs" :key="i.index" :value="i.index">{{ i.title }}</option>
                    </select>
                  </div>
                  <div class="col-12 text-center g" v-if="phone_input !== '' && name_input !== '' && (group_input != '' && group_id == 'create-groups' || group_id != 'create-groups')">
                    <button class="btn btn-primary" @click="processExcel()" v-if="contacts.length == 0">معالجة الان</button>
                  </div>
              </div>
              <div
                class="row"
                v-for="(contact, index) in contacts"
                :key="index"
              >
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="">الإسم (اختياري)</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="contact.name"
                    />
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label for="">الهاتف</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="contact.phone"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- import tahdir -->
          <div class="col-12 col-lg-7" v-if="type == 10">
            <div class="card card-body border">
              <div class="col-12">
                <p>
                  بيانات الدخول الخاصة بالتحضير الذكي
                </p>
                <div class="form-group">
                  <label for="">رقم الجوال</label>
                  <input type="text"
                    class="form-control" v-model="tahdir_phone" placeholder="اكتب هنا...">
                </div>
                <div class="form-group">
                  <label for="">كلمة المرور</label>
                  <input type="password"
                    class="form-control" v-model="tahdir_password" placeholder="اكتب هنا...">
                </div>
                <div class="form-group">
                  <label for="">استيراد الطلاب</label>
                  <select class="form-control" v-model="tahdir_students">
                    <option value="groups">وضع كل صف في مجموعة</option>
                    <option value="no">عدم استيراد الطلاب</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="">استيراد المعملين</label>
                  <select class="form-control" v-model="tahdir_teachers">
                    <option value="yes">نعم</option>
                    <option value="no">لا</option>
                  </select>
                </div>
                <div class="col-12 text-center g">
                  <button class="btn btn-primary" @click="tahdir()" v-if="!tahdir_loading">
                    استيراد البيانات من حسابك
                  </button>
                  <button class="btn btn-primary disabled" disabled v-if="tahdir_loading">
                    جاري التحميل...
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-12 text-center"
            v-if="type != 0 && contacts.length > 0"
          >
            <button class="btn btn-relief-success" @click="addContacts()">
              إضافة الأرقام الآن
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BOverlay, BModal, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BOverlay,
    BModal,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  data() {
    return {
      tahdir_password: "",
      tahdir_loading: false,
      tahdir_phone: "",
      tahdir_students: "groups",
      tahdir_teachers: "no",
      createGroup: {},
      textareavalue: "",
      contacts: [],
      loading: false,
      group_id: null,
      groups: [],
      type: 0,
      data: [],
      numbers_to_add: 10,
      inputs: [],
      lll: false,
      phone_input: "",
      name_input: "",
      group_input: "",
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    var g = this;
    $.post(api + "/user/contacts/groups/list", {
      jwt: this.user.jwt,
    })
      .then(function (r) {
        g.groups = r.response;
        g.loading = false;
      })
      .catch(function () {
        g.loading = false;
        g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدثت مشكلة في الاتصال",
            icon: "EditIcon",
            variant: "danger",
          },
        });
      });
  },
  methods: {
    tahdir(){
      var g = this;
      g.tahdir_loading = true
      $.post(api + "/user/contacts/tahdir", {
        jwt: this.user.jwt,
        phone: this.tahdir_phone,
        password: this.tahdir_password,
        students: this.tahdir_students,
        teachers: this.tahdir_teachers
      })
        .then(function (r){
          g.tahdir_loading = false
          if(typeof r == 'string'){
            r = JSON.parse(r)
          }
          if(r.status != 100){
            g.$toast({
              component: ToastificationContent,
              props: {
                title: r.response,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          }else{
          g.$toast({
              component: ToastificationContent,
              props: {
                title: "تم الاستيراد بنجاح!",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            g.$router.push('/contacts')
          }
        })
        .catch(function () {
          g.tahdir_loading = false
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدثت مشكلة في الاتصال",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
    },
    addNumbers() {
      var g = this;
      for (var i = 0; i < this.numbers_to_add; i++) {
        g.contacts.push({
          name: "",
          phone: "",
        });
      }
    },
    textareaNumbersHandle() {
      var g = this;
      g.contacts = [];
      this.textareavalue.split("\n").forEach(function (line) {
        if (line != "") {
          var ex = false;
          g.contacts.forEach(function (a) {
            if (a.phone?.trim() == line.split(",")[0]?.trim()) {
              ex = true;
            }
          });
          if (!ex) {
            g.contacts.push({
              name: line?.split(",")[1] ?? line?.split("،")[1],
              phone: line.split(",")[0]?.trim(),
            });
          }
        }
      });
    },
    listenCSV() {
      var g = this;
      setTimeout(() => {
        document
          .getElementById("upload")
          .addEventListener("change", upload, false);
        function upload(e) {
          var data = null;
          var file = e.target.files[0];

          var reader = new FileReader();
          reader.readAsText(file);
          reader.onload = function (event) {
            var csvData = event.target.result;
            event.target.result.split("\n").forEach(function (line) {
              if (line != "") {
                var ex = false;
                g.contacts.forEach(function (a) {
                  if (a.phone?.trim() == line.split(",")[0]?.trim()) {
                    ex = true;
                  }
                });
                if (!ex) {
                  g.contacts.push({
                    name: line?.split(",")[1]?.replaceAll('"', "")?.trim(),
                    phone: line.split(",")[0]?.replaceAll('"', "")?.trim()
                  });
                }
              }
            });
          };
        }
      }, 500);
    },
    listenGoogleContacts() {
      var g = this;
      setTimeout(() => {
        document
          .getElementById("upload")
          .addEventListener("change", upload, false);
        function upload(e) {
          var data = null;
          var file = e.target.files[0];

          var reader = new FileReader();
          reader.readAsText(file);
          reader.onload = function (event) {
            var csvData = event.target.result;
            event.target.result.split("\n").forEach(function (line) {
              if (line != "") {
                if (line?.split(",")[0] != "Name") {
                  var ex = false;
                  g.contacts.forEach(function (a) {
                    if (a.phone?.trim() == line.split(",")[0]?.trim()) {
                      ex = true;
                    }
                  });
                  if (!ex) {
                    g.contacts.push({
                      name: line?.split(",")[0],
                      phone: line.split(",")[30]?.trim()?.replaceAll(" ", ""),
                    });
                  }
                }
              }
            });
          };
        }
      }, 500);
    },
    listenNoor() {
      var g = this;
      g.group_id = "create-groups"
      var Upload = function (file) {
        this.file = file;
      };

      Upload.prototype.getType = function () {
        return this.file.type;
      };
      Upload.prototype.getSize = function () {
        return this.file.size;
      };
      Upload.prototype.getName = function () {
        return this.file.name;
      };
      Upload.prototype.doUpload = function () {
        var that = this;
        var formData = new FormData();

        // add assoc key values, this will be posts values
        formData.append("file", this.file, this.getName());
        formData.append("upload_file", true);

        $.ajax({
          type: "POST",
          url: "https://private-api.brmja.com/excel",
          xhr: function () {
            var myXhr = $.ajaxSettings.xhr();
            if (myXhr.upload) {
              myXhr.upload.addEventListener(
                "progress",
                that.progressHandling,
                false
              );
            }
            return myXhr;
          },
          success: function (data) {
            // your callback here
            $("#progress-wrp").fadeOut("slow");
            data.forEach(function (student) {
              if (student[5]) {
                student[5] = student[5].toString();
                if (
                  parseInt(student[5]) != "NanN" &&
                  student[5].trim() != "" &&
                  !student[5].includes("ا")
                ) {
                  g.contacts.push({
                    name: student[4],
                    phone: student[1],
                    group: student[3]
                  });
                }
              }
            });
          },
          error: function (error) {
            // handle error
            alert("حدث خطأ");
          },
          async: true,
          data: formData,
          cache: false,
          contentType: false,
          processData: false,
          timeout: 60000,
        });
      };

      Upload.prototype.progressHandling = function (event) {
        var percent = 0;
        var position = event.loaded || event.position;
        var total = event.total;
        var progress_bar_id = "#progress-wrp";
        if (event.lengthComputable) {
          percent = Math.ceil((position / total) * 100);
        }
        // update progressbars classes so it fits your code
        $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
        $(progress_bar_id + " .status").text(percent + "%");
      };
      setTimeout(() => {
        $("#ingredient_file").on("change", function (e) {
          $("#progress-wrp").fadeIn("slow");
          var file = $(this)[0].files[0];
          var upload = new Upload(file);

          // maby check size or type here with upload.getSize() and upload.getType()

          // execute upload
          upload.doUpload();
        });
      }, 1000);
    },
    listenXLS() {

      var g = this;

      g.group_id = "create-groups"
      var Upload = function (file) {
        this.file = file;
      };

      Upload.prototype.getType = function () {
        return this.file.type;
      };
      Upload.prototype.getSize = function () {
        return this.file.size;
      };
      Upload.prototype.getName = function () {
        return this.file.name;
      };
      Upload.prototype.doUpload = function () {
        var that = this;
        var formData = new FormData();

        // add assoc key values, this will be posts values
        formData.append("file", this.file, this.getName());
        formData.append("upload_file", true);

        $.ajax({
          type: "POST",
          url: "https://private-api.brmja.com/excel",
          xhr: function () {
            var myXhr = $.ajaxSettings.xhr();
            if (myXhr.upload) {
              myXhr.upload.addEventListener(
                "progress",
                that.progressHandling,
                false
              );
            }
            return myXhr;
          },
          success: function (data) {
            // your callback here
            $("#progress-wrp").fadeOut("slow");
            g.data = data[0]
            var index = -1
            g.inputs = g.data[0].map(function(a){
                index++
                if(a){
                    return {
                      title: a?.toString().trim(),
                      index: index
                  }
                }else{
                  return false;
                }
            }).filter(function(x){return x})

          },
          error: function (error) {
            // handle error
            alert("حدث خطأ");
          },
          async: true,
          data: formData,
          cache: false,
          contentType: false,
          processData: false,
          timeout: 60000,
        });
      };

      Upload.prototype.progressHandling = function (event) {
        var percent = 0;
        var position = event.loaded || event.position;
        var total = event.total;
        var progress_bar_id = "#progress-wrp";
        if (event.lengthComputable) {
          percent = Math.ceil((position / total) * 100);
        }
        // update progressbars classes so it fits your code
        $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
        $(progress_bar_id + " .status").text(percent + "%");
      };
      setTimeout(() => {
        $("#ingredient_file").on("change", function (e) {
          $("#progress-wrp").fadeIn("slow");
          var file = $(this)[0].files[0];
          var upload = new Upload(file);

          // maby check size or type here with upload.getSize() and upload.getType()

          // execute upload
          upload.doUpload();
        });
      }, 1000);
    },
    processExcel(){
      var g = this;
      g.data.forEach(function(a){
        if(a[g.phone_input]){
            g.contacts.push({
            name: a[g.name_input],
            phone: a[g.phone_input] ?? "",
            group: g.group_id == 'create-groups' ? a[g.group_input] : g.group_id
          })
        }
      })
    },
    createGroupNow() {
      var g = this;
      if (this.createGroup.title && this.createGroup?.title?.trim() != "") {
        this.loading = true;
        $.post(api + "/user/contacts/groups/create", {
          jwt: g.user.jwt,
          title: g.createGroup.title,
        })
          .then(function (r) {
            g.loading = false;
            if (r.status == 100) {
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: "تم انشاء المجموعة بنجاح",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              g.groups = r.response;
              $("#modal-create-group___BV_modal_header_ > button").click();
              g.group_id = g.groups[g.groups.length - 1]._id;
            } else {
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: r.response,
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            }
          })
          .catch(function () {
            g.loading = false;
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدثت مشكلة في الاتصال",
                icon: "EditIcon",
                variant: "danger",
              },
            });
          });
      } else {
        g.$toast({
          component: ToastificationContent,
          props: {
            title: "برجاء كتابة العنوان",
            icon: "EditIcon",
            variant: "danger",
          },
        });
      }
    },
    addContacts() {
      this.lll = true;
      var g = this,
        add = false;
      g.contacts.forEach(function (c) {
        if (c.phone != "") {
          add = true;
        }
      });
      if (add) {
        this.loading = true;
        $.post(api + "/user/contacts/insert", {
          jwt: g.user.jwt,
          group_id: g.group_id,
          contacts: JSON.stringify(g.contacts),
        })
          .then(function (r) {
            g.lll = false
            g.loading = false;
            if (r.status == 100) {
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: "تم اضافة الارقام بنجاح",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              g.$router.push("/contacts");
            } else {
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: r.response,
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            }
          })
          .catch(function () {
            g.loading = false;
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدثت مشكلة في الاتصال",
                icon: "EditIcon",
                variant: "danger",
              },
            });
          });
      } else {
        g.$toast({
          component: ToastificationContent,
          props: {
            title: "لا يوجد ارقام لاضافتها",
            icon: "EditIcon",
            variant: "danger",
          },
        });
      }
    },
  },
};
</script>

<style>
.select-way-to-add:hover {
  background: #eee !important;
  cursor: pointer;
}
</style>

<style scoped>
#progress-wrp {
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
  display: none;
}

#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: greenyellow;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
</style>